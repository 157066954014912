<template>
	<el-dialog title="" :visible.sync="newActivityVisible" width="576px" :close-on-click-modal="false" @close="onResetForm('ruleForm')" center>
		<div slot="title" class="postion">
			<i class="iconfont icon-bianji1" v-if="!isShowDetail"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}活动</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="活动名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item prop="activityTypeDictionaryItemId" label="活动类型">
				<el-select :disabled="isShowDetail" v-model="ruleForm.activityTypeDictionaryItemId" placeholder="请选择">
					<el-option v-for="(item, index) in activityList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="活动时间" prop="activityTime">
				<el-date-picker
					v-model="ruleForm.activityTime"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd HH:mm:ss"
					:disabled="isShowDetail"
					:picker-options="activityTimePickerOptions"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="报名时间" prop="registrationTime">
				<el-date-picker
					v-model="ruleForm.registrationTime"
					:picker-options="secondPickerOptions"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					:disabled="isShowDetail"
					value-format="yyyy-MM-dd HH:mm:ss"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="联系人" required class="concats clearfix">
				<el-form-item class="float-l concats-content" prop="contactName">
					<el-input v-model.trim="ruleForm.contactName" :disabled="isShowDetail" placeholder="姓名"></el-input>
				</el-form-item>
				<el-form-item class="float-l concats-content mr0" prop="phoneNumber">
					<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="联系电话"></el-input>
				</el-form-item>
			</el-form-item>
			<el-form-item label="价格" prop="price">
				<el-input v-model.trim="ruleForm.price" :disabled="isShowDetail"></el-input>
			</el-form-item>
			<el-form-item label="活动介绍" prop="description">
				<el-input type="textarea" :disabled="isShowDetail" v-model.trim="ruleForm.description" autosize></el-input>
			</el-form-item>
			<el-form-item class="attractionAddress" label="活动地址" prop="address">
				<el-input v-model.trim="ruleForm.address" disabled placeholder="选择地址" :style="{ width: isShowDetail ? '100%' : '' }"></el-input>
				<el-button type="text" @click="chooseAddress" v-if="!isShowDetail">选择地址</el-button>
			</el-form-item>
			<el-form-item label="活动封面图" prop="homeImages">
				<Upload
					v-model="ruleForm.homeImages"
					modulePath="ACTIVITY"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
		<ChooseAddress ref="chooseAddress" @changeAddress="changeAddress" />
	</el-dialog>
</template>

<script>
import { verifyPhone, verifiyPrice } from '@/utils/toolsValidate';
export default {
	data() {
		var validatePhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系电话'));
			} else {
				if (!verifyPhone(this.ruleForm.phoneNumber)) {
					callback(new Error('请输入正确的联系方式'));
				}
				callback();
			}
		};
		var checkPrice = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入单次服务费用'));
			} else if (!verifiyPrice(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			newActivityVisible: false,
			activityList: [],
			ruleForm: {
				name: '',
				activityTypeDictionaryItemId: null,
				activityTime: [],
				contactName: '',
				phoneNumber: '',
				description: '',
				address: '',
				homeImages: [],
				price: '',
				registrationTime: [],
			},
			rules: {
				name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
				address: [{ required: true, message: '请输入地址', trigger: 'change' }],
				description: [{ required: true, message: '请输入活动介绍', trigger: 'blur' }],
				price: [{ required: true, validator: checkPrice, trigger: 'blur' }],
				contactName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: validatePhone, trigger: 'blur' }],
				activityTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				homeImages: [{ required: true, message: '请上传活动照片', trigger: 'blur' }],
				activityTime: [{ required: true, message: '请选择', trigger: 'change' }],
				registrationTime: [{ required: true, message: '请选择', trigger: 'change' }],
			},
			saveLoading: false,
			link: '',
			secondPickerOptions: {
				disabledDate: (time) => {
					if (this.ruleForm.activityTime && this.ruleForm.activityTime.length > 0) {
						return time.getTime() > new Date(this.ruleForm.activityTime[1]).getTime();
					} else {
						return false;
					}
				},
			},
			activityTimePickerOptions: {
				disabledDate: (time) => {
					if (this.ruleForm.registrationTime && this.ruleForm.registrationTime.length > 0) {
						return time.getTime() < new Date(this.ruleForm.registrationTime[0]).getTime();
					} else {
						return false;
					}
				},
			},
		};
	},
	props: ['isShowDetail', 'activityTypeList'],
	computed: {
		fullHeight() {
			return window.innerHeight - 340;
		},
	},
	methods: {
		init(data) {
			this.newActivityVisible = true;
			this.link = data.link;
			this.getAttachmentGroups();
			this.activityList = this.activityTypeList.filter((item) => item.id !== '');
			if (data.row) {
				this.getActivityDetail(data.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 查询活动详情
		getActivityDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						let resultObj = res.data.collection[0] || {};
						this.ruleForm = JSON.parse(JSON.stringify(resultObj));
						this.$set(this.ruleForm, 'activityTime', [resultObj.startTime, resultObj.endTime]);
						this.$set(this.ruleForm, 'registrationTime', [resultObj.registrationStartTime, resultObj.registrationEndTime]);
						this.$set(this.ruleForm, 'activityTypeDictionaryItemId', resultObj.activityTypeDictionaryItem?.id);
						this.ruleForm.price = (resultObj.price && resultObj.price / 100) || '';
						this.ruleForm.homeImages =
							(resultObj.attachmentMap &&
								resultObj.attachmentMap['PHOTO'].map((v) => {
									return {
										url: v.link,
										id: v.id,
										title: v.title,
									};
								})) ||
							[];
					}
				})
				.catch((e) => {});
		},
		uploadImg(dataImg) {
			this.ruleForm.homeImages = dataImg;
		},
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let data = {
						...this.ruleForm,
						price: this.ruleForm.price * 100,
						startTime: this.ruleForm.activityTime?.length > 0 ? this.ruleForm.activityTime[0] : '',
						endTime: this.ruleForm.activityTime?.length > 0 ? this.ruleForm.activityTime[1] : '',
						registrationStartTime: this.ruleForm.registrationTime?.length > 0 ? this.ruleForm.registrationTime[0] : '',
						registrationEndTime: this.ruleForm.registrationTime?.length > 0 ? this.ruleForm.registrationTime[1] : '',
						attachments: this.ruleForm.homeImages.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					let requestType = this.ruleForm.id ? this.$http.put(this.link, data) : this.$http.post(this.link, data);
					requestType
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		// 选择地址
		chooseAddress() {
			let { latitude, longitude } = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {};
			this.$refs.chooseAddress.center = [longitude || 0, latitude || 0];
			this.$refs.chooseAddress.addressText = this.ruleForm.address || '';
			this.$refs.chooseAddress.chooseAddressVisible = true;
		},
		// 获取到的地址
		changeAddress(data) {
			console.log(data, 'address');
			this.ruleForm.address = data.address;
			this.ruleForm.longitude = data.lng;
			this.ruleForm.latitude = data.lat;
			this.ruleForm.regionCode = data.addressData.adcode;
		},
		onResetForm(formName) {
			this.newActivityVisible = false;
			this.ruleForm.homeImages = [];
			this.$refs[formName].resetFields();
		},
		// 获取附件组
		getAttachmentGroups() {
			this.$http.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'HEALTH_ACTIVITY' } }).then((res) => {
				if (res.data && res.data.success) {
					this.attachmentInfo = res.data.collection || [];
				}
			});
		},
	},
	components: {
		ChooseAddress: () => import('./chooseAddress.vue'),
		Upload: () => import('@/components/Upload/index.vue'),
		// ImageAndVideo: () => import('@/components/ImageAndVideo/index.vue'),
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #5374f0;
	}
}
.el-form {
	.el-form-item {
		.el-input,
		.el-select {
			width: 100%;
		}
	}
	/deep/ .el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.concats {
		&-content {
			width: calc((100% - 16px) / 2);
			margin-right: 16px;
		}
	}
	/deep/ .el-range-editor {
		width: 100%;
		.el-range-separator,
		.el-range__icon {
			line-height: 24px;
		}
	}
	/deep/ .el-upload--picture-card {
		background-color: #f7f8fa;
	}
	.attractionAddress {
		.el-input {
			width: calc(100% - 74px);
		}
		/deep/ .el-button {
			width: 56px;
			border-bottom: 1px solid #5b7cfd;
			margin-left: 16px;
			padding: 0;
			&:hover {
				color: #3b60f3;
				border-bottom: 1px solid #3b60f3;
			}
		}
	}
	/deep/ .el-range-editor.is-disabled {
		background-color: #f4f3f3 !important;
		input {
			background-color: #f4f3f3 !important;
		}
	}
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>